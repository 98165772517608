import React from "react";
import { navigateTo } from "gatsby-link";
import SEO from '../components/seo';
import Header from '../components/header'
import Layout from '../components/layout'
import Container from '../components/Container'
import Box from '../components/Box'
import Flex from '../components/Flex'
import Hero from '../components/Hero'
import Text from '../components/Text'
import InputText from '../components/InputText'
import InputFile from '../components/InputFile'
import InputDate from '../components/InputDate'
import TextArea from '../components/TextArea'
import FormLabel from '../components/FormLabel'
import Button from '../components/Button'
import '../css/form.css'


function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
        <Layout>
            <SEO title='Hire talent' /> 
            <Header />
            <Hero title="Hire top talent, from intern to advisor." />
            <Container as="main" id='main-content'>
                <form
                name="Post Job"
                method="post"
                action="/success/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                >     
                  <Box as="fieldset" p={['3', '4']} border="solid 2px #e5e5e5">
                    <Text as="legend" fontWeight="600">JOB</Text>
                    <Box mb="3">
                      <FormLabel htmlFor="position">Job Position*</FormLabel>
                      <InputText id="position" name="position" type="text" onChange={this.handleChange} required/>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please specify a single job role like “Business Development Manager” and avoid using accronyms. For example CEO should be written “Chief Executive Officer”.</Text>
                    </Box>
                    <Box mb="3">
                      <FormLabel htmlFor="company-name">Company Name*</FormLabel>
                      <InputText id="company-name" name="company-name" type="text" onChange={this.handleChange} required/>
                    </Box>
                    <Box mb="3">
                      <FormLabel htmlFor="location">Location</FormLabel>
                      <InputText id="location" name="location" type="text" onChange={this.handleChange}/>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please specify the city or county where the job is located.</Text>
                    </Box>
                    <Box mb="3">
                        <FormLabel htmlFor="job-description">Job Description*</FormLabel>
                        <TextArea id="job-description" name="description" type="text" rows="6" maxLength="750" onChange={this.handleChange}/>
                        <Text fontSize="1" color="light" lineHeight="1.3">Please describe the role you are hiring for. Max limit: 750 characters.</Text>
                    </Box>
                    <Box mb="3">
                        <FormLabel htmlFor="position-type">Position Type</FormLabel>
                        <div id="position-type" className="select">
                        <select name="position-type" onChange={this.handleChange}>
                            <option value="0" name="position-type" onChange={this.handleChange}>Please Select</option>
                            <option value="Full Time" name="position-type" onChange={this.handleChange}>Full Time</option>
                            <option value="Part Time" name="position-type" onChange={this.handleChange}>Part Time</option>
                            <option value="Freelance" name="position-type" onChange={this.handleChange}>Freelance</option>
                            <option value="Volunteer" name="position-type" onChange={this.handleChange}>Volunteer</option>
                        </select>
                        <span className="select__arrow"></span>
                      </div>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please select the type of position this is.</Text>
                    </Box>

                    <Box mb="3">
                        <FormLabel htmlFor="job-function">Job Function</FormLabel>
                        <div id="job-function" className="select">
                        <select name="job-function" onChange={this.handleChange}>
                            <option value="0" name="job-function" onChange={this.handleChange}>Please Select</option>
                            <option value="Administration" name="job-function" onChange={this.handleChange}>Administration</option>
                            <option value="Chairman" name="job-function" onChange={this.handleChange}>Chairman</option>
                            <option value="Co-founder" name="job-function" onChange={this.handleChange}>Co-founder</option>
                            <option value="Customer support" name="job-function" onChange={this.handleChange}>Customer support</option>
                            <option value="Data science" name="job-function" onChange={this.handleChange}>Data science</option>
                            <option value="Design" name="job-function" onChange={this.handleChange}>Design</option>
                            <option value="Development" name="job-function" onChange={this.handleChange}>Development</option>
                            <option value="Information technology" name="job-function" onChange={this.handleChange}>Information technology</option>
                            <option value="Intern" name="job-function" onChange={this.handleChange}>Intern</option>
                            <option value="Legal" name="job-function" onChange={this.handleChange}>Legal</option>
                            <option value="Management" name="job-function" onChange={this.handleChange}>Management</option>
                            <option value="Manufacturing" name="job-function" onChange={this.handleChange}>Manufacturing</option>
                            <option value="Marketing" name="job-function" onChange={this.handleChange}>Marketing</option>
                            <option value="Non-executive Director" name="job-function" onChange={this.handleChange}>Non-executive Director</option>
                            <option value="Sales" name="job-function" onChange={this.handleChange}>Sales</option>
                        </select>
                        <span className="select__arrow"></span>
                      </div>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please select the type of position this is.</Text>
                    </Box>
                    
                    <Box mb="3">
                      <FormLabel htmlFor="experience">Experience Level</FormLabel>
                      <div id="experience" className="select">
                        <select name="experience" onChange={this.handleChange}>
                            <option value="0" name="experience" onChange={this.handleChange}>Please Select</option>
                            <option value="Entry Level" name="experience" onChange={this.handleChange}>Entry Level</option>
                            <option value="Junior Level" name="experience" onChange={this.handleChange}>Junior</option>
                            <option value="Mid Level" name="experience" onChange={this.handleChange}>Mid Level</option>
                            <option value="Senior Level" name="experience" onChange={this.handleChange}>Senior Level</option>
                            <option value="Director" name="experience" onChange={this.handleChange}>Director</option>
                            <option value="Executive" name="experience" onChange={this.handleChange}>Executive</option>
                        </select>
                        <span className="select__arrow"></span>
                      </div>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please select the level of experience required by the applicant.</Text>
                    </Box>
                    <Box mb="3">
                      <FormLabel htmlFor="industry">Primary Industry*</FormLabel>
                      <div id="industry" className="select">
                        <select name="industry" onChange={this.handleChange}>
                            <option value="0" name="industry" onChange={this.handleChange}>Please Select</option>
                            <option value="Agriculture" name="industry" onChange={this.handleChange}>Agriculture</option>
                            <option value="Business Services" name="industry" onChange={this.handleChange}>Business Services</option>
                            <option value="Education &amp; Training" name="industry" onChange={this.handleChange}>Education &amp; Training</option>
                            <option value="Energy &amp; Natural Resources" name="industry" onChange={this.handleChange}>Energy &amp; Natural Resources</option>
                            <option value="Entertainment &amp; Leisure" name="industry" onChange={this.handleChange}>Entertainment &amp; Leisure</option>
                            <option value="Fashion &amp; Beauty" name="industry" onChange={this.handleChange}>Fashion &amp; Beauty</option>
                            <option value="Finance" name="industry" onChange={this.handleChange}>Finance</option>
                            <option value="Food &amp; Beverage" name="industry" onChange={this.handleChange}>Food &amp; Beverage</option>
                            <option value="Hospitality, Restaurants &amp; Bars" name="industry" onChange={this.handleChange}>Hospitality, Restaurants &amp; Bars</option>
                            <option value="Manufacturing &amp; Engineering" name="industry" onChange={this.handleChange}>Manufacturing &amp; Engineering</option>
                            <option value="Media" name="industry" onChange={this.handleChange}>Media</option>
                            <option value="Medical &amp; Sciences" name="industry" onChange={this.handleChange}>Medical &amp; Sciences</option>
                            <option value="Personal Services" name="industry" onChange={this.handleChange}>Personal Services</option>
                            <option value="Products &amp; Inventions" name="industry" onChange={this.handleChange}>Products &amp; Inventions</option>
                            <option value="Property" name="industry" onChange={this.handleChange}>Property</option>
                            <option value="Retail" name="industry" onChange={this.handleChange}>Retail</option>
                            <option value="Sales &amp; Marketing" name="industry" onChange={this.handleChange}>Sales &amp; Marketing</option>
                            <option value="Software" name="industry" onChange={this.handleChange}>Software</option>
                            <option value="Technology" name="industry" onChange={this.handleChange}>Technology</option>
                            <option value="Transportation" name="industry" onChange={this.handleChange}>Transportation</option>
                        </select>
                        <span className="select__arrow"></span>
                      </div>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please select a primary tag to improve the visability of your listing.</Text>
                    </Box>
                    
                    <Box mb="3">
                      <Box mb="2">
                        <FormLabel htmlFor="remote">Is remote work possible?</FormLabel>
                      </Box>
                        <fieldset id="remote">
                          <label className="control control--radio">Yes
                            <input type="radio" value="Yes" name="remote" onChange={this.handleChange}/>
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">No
                            <input type="radio" value="No" name="remote" onChange={this.handleChange}/>
                            <div className="control__indicator"></div>
                          </label>
                        </fieldset>
                    </Box>
                    <Box as="fieldset" mb="3" p={['3', '4']} border="solid 2px #e5e5e5">
                        <Text as="legend" fontWeight="600">Job Requirements</Text>
                        <Flex mb="3" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="job-requirement-one">Requirement 1</FormLabel>
                          <InputText id="job-requirement-one" name="job-requirement-one" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Flex mb="3" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="job-requirement-two">Requirement 2</FormLabel>
                          <InputText id="job-requirement-two" name="job-requirement-two" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Flex mb="3" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="job-requirement-three">Requirement 3</FormLabel>
                          <InputText id="job-requirement-three" name="job-requirement-three" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Flex mb="1" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="job-requirement-four">Requirement 4</FormLabel>
                          <InputText id="job-requirement-four" name="job-requirement-four" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Text fontSize="1" color="light" py="1" lineHeight="1.3" ml={['0', '150px']}>Please list the skills required by the applicant. Max limit: 96 characters per requirement.</Text>
                    </Box>
                    
                    <Box mb="3">
                        <FormLabel htmlFor="deadline">Application Deadline</FormLabel>
                        <InputDate type="date" name="deadline" placeholder="e.g. dd/mm/yyyy" onChange={this.handleChange}/>
                        <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please specify the deadline for applications.</Text>
                    </Box>
                    
                  </Box>
                  <Box as="fieldset" mt="4" p={['3', '4']} border="solid 2px #e5e5e5">
                    <Text as="legend" fontWeight="600">COMPANY</Text>
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <InputText type="hidden" name="form-name" value="Post Job" />
                    <p hidden>
                        <label>
                        Don’t fill this out:{" "}
                        <InputText name="bot-field" onChange={this.handleChange} />
                        </label>
                    </p>
                   
                    <Box mb="3">
                        <FormLabel htmlFor="company-url">Company Website</FormLabel>
                        <InputText id="company-url" name="company-url" type="url" onChange={this.handleChange}/>
                        <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please specify a URL address like “https://seedtribe.com/”.</Text>
                    </Box>
                    <Box mb="3">
                        <FormLabel htmlFor="company-logo">Company Logo</FormLabel>
                        <InputFile id="company-logo" type="file" name="attachment" onChange={this.handleAttachment}/>
                        <Text fontSize="1" color="light" lineHeight="1.3">Please upload a .jpg file.</Text>
                    </Box>
                    <Box mb="3">
                        <FormLabel htmlFor="company-mission">Company Mission</FormLabel>
                        <TextArea id="company-mission" name="mission" type="text" rows="6" maxLength="300" onChange={this.handleChange}/>
                        <Text fontSize="1" color="light" lineHeight="1.3">Briefly describe what your company does. Max limit: 300 characters.</Text>
                    </Box>
                    <Box as="fieldset" mb="3" p={['3', '4']} border="solid 2px #e5e5e5">
                        <Text as="legend" fontWeight="600">Company Highlights</Text>
                        <Flex mb="3" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="company-highlight-one">Highlight 1</FormLabel>
                          <InputText id="company-highlight-one" name="company-highlight-one" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Flex mb="3" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="company-highlight-two">Highlight 2</FormLabel>
                          <InputText id="company-highlight-two" name="company-highlight-two" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Flex mb="3" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="company-highlight-three">Highlight 3</FormLabel>
                          <InputText id="company-highlight-three" name="company-highlight-three" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Flex mb="1" flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                          <FormLabel htmlFor="company-highlight-four">Highlight 4</FormLabel>
                          <InputText id="company-highlight-four" name="company-highlight-four" type="text" maxLength="96" onChange={this.handleChange}/>
                        </Flex>
                        <Text fontSize="1" color="light" py="1" lineHeight="1.3" ml={['0', '150px']}>Sell your company in 4 bullet points. Max limit: 96 characters per highlight.</Text>
                    </Box>                   
                    <Box mb="3">
                      <FormLabel htmlFor="employees">Number of Employees</FormLabel>
                      <div id="employees" className="select">
                        <select name="employees" onChange={this.handleChange}>
                            <option value="0" name="employees" onChange={this.handleChange}>Please Select</option>
                            <option value="0-5" name="employees" onChange={this.handleChange}>0-5</option>
                            <option value="5-10" name="employees" onChange={this.handleChange}>5-10</option>
                            <option value="10-25" name="employees" onChange={this.handleChange}>10-25</option>
                            <option value="25-50" name="employees" onChange={this.handleChange}>25-50</option>
                            <option value="50-100" name="employees" onChange={this.handleChange}>50-100</option>
                            <option value="100+" name="employees" onChange={this.handleChange}>100+</option>
                        </select>
                        <span className="select__arrow"></span>
                      </div>
                      <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please select the number of employees currently working at the company.</Text>
                    </Box>
                    <Box as="fieldset" mb="3" p={['3', '4']} border="solid 2px #e5e5e5">
                      <Text as="legend" fontWeight="600">Social Links</Text>
                      <Box mb="3">
                          <FormLabel htmlFor="company-twitter">Company Twitter</FormLabel>
                          <InputText id="company-twitter" name="company-twitter" type="url" onChange={this.handleChange}/>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Used to Tweet about your listing. Please add the URL like “https://twitter.com/Seedtribe/”.</Text>
                      </Box>
                      <Box mb="3">
                          <FormLabel htmlFor="company-linkedin">Company LinkedIn</FormLabel>
                          <InputText id="company-linkedin" name="company-linkedin" type="url" onChange={this.handleChange}/>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Used to promote your listing. Please add the URL like “https://www.linkedin.com/company/seedtribe/”.</Text>
                      </Box>
                      <Box mb="3">
                          <FormLabel htmlFor="founder-linkedin">Founder LinkedIn</FormLabel>
                          <InputText id="founder-linkedin" name="founder-linkedin" type="url" onChange={this.handleChange}/>
                          <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please add the LinkedIn address of the founder.</Text>
                      </Box>
                    </Box>
                    <Box mb="3">
                        <FormLabel htmlFor="contact-email">Contact Email</FormLabel>
                        <InputText id="contact-email" name="email" type="email" onChange={this.handleChange}/>
                        <Text fontSize="1" color="light" py="1" lineHeight="1.3">Please provide your email address. This will remain private.</Text>
                    </Box>
                  </Box>
                  <Box my="3">
                      <Button variant="primary" id="submit-button" type="submit" minWidth="400px"><span role="img" aria-label="party emoji">🎉</span> Post your job now – for free!</Button>
                  </Box>
                </form>
            </Container>
        </Layout>
    );
  }
}