import styled from 'styled-components'

const InputText = styled.input.attrs({
    type: 'file',
  })`
    background-color: white;
    border: 0;
    color: #3A3F51;
    max-width: 100%;
    padding: 8px;
    width: 100%;

    :hover {
        border: 0;
        outline: 0;
    }

    :active,
    :focus {
        border-color: #4353FF;
        outline: 3px solid #D9DDFF;
        outline-offset: 0

    }
`


export default InputText